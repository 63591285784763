<template>
  <div id="email-report">
    <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
    <EmailTable
      :data="data"
      :expanded="expanded"
      :page="page"
      :page-size="pageSize"
      @fetch="fetchEmailReport"
      @page-change="onPageChange"
      @update:expanded="id => $set(expanded, id, !expanded[id])"
    >
      <template #details="{ item }">
        <CCollapse :show="expanded[item.id]">
          <EmailTable
            :data="item.messages.slice(1)"
            :options="{ addTableClasses: 'conversation' } "
          />
        </CCollapse>
      </template>
    </EmailTable>
  </div>
</template>

<script>
import { formatISO } from 'date-fns'

import { RTableFilterOptions } from '@/components/tables'
import { paginatedRequest } from '@/services/api'
import { ReportingAPI } from '@/services/api/resources'
import { shallowCopy } from '@/utils'

import EmailTable from './EmailTable'

export default {
  name: 'EmailReport',
  components: {
    EmailTable,
    RTableFilterOptions
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: {
        results: [],
        count: 0,
        page: this.page,
        page_size: this.pageSize
      },
      expanded: {},
      page: 1,
      pageSize: 50,
      filters: {
        sent: [],
        search: ''
      },
      filterConfig: {
        sent: {
          type: 'multiselect',
          label: 'Sent',
          options: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        },
        search: {
          type: 'input',
          label: 'Search'
        }
      }
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.page = 1
        this.fetchEmailReport()
      }
    }
  },
  async created () {
    await this.fetchEmailReport()

    if (this.data.results.length === 0) {
      this.$emit('no-data')
    }
  },
  methods: {
    getParams () {
      const params = new URLSearchParams()
      params.append('from_date', formatISO(this.dateRange.start))
      params.append('to_date', formatISO(this.dateRange.end))
      params.append('tz', this.dateRange.tz)
      params.append('page', this.page)
      // params.append('page_size', this.pageSize)

      if (this.filters.sent && this.filters.sent.length === 1) {
        params.append('forwarded', this.filters.sent)
      }
      if (this.filters.search) {
        params.append('q', this.filters.search)
      }
      return params
    },
    /**
     * Fetch the email report.
     */
    async fetchEmailReport () {
      this.$emit('loading', true)
      const params = this.getParams()
      params.append('page_size', this.pageSize)

      const response = await ReportingAPI.property.emails({
        id: this.$route.params.pid,
        params
      })
      if (response) {
        this.data = response.data
        this.data.results = this.data.results.map(item => ({ ...item, ...item.messages[0] }))
      }
      this.$emit('loading', false)
    },
    /**
     * event handler for change to the selected page or page size.
     */
    onPageChange (pageData) {
      this.page = pageData.page
      this.pageSize = pageData.pageSize
      this.fetchEmailReport()
    },
    /**
     * Update search filters and fetch data again.
     */
    handleFilterChange (filters) {
      this.filters = filters
      this.fetchEmailReport()
    },
    /**
     * onRefresh handler called by BaseReport component.
     */
    refresh () {
      this.fetchEmailReport()
    },
    /**
     * onExport handler called by BaseReport component.
     */
    async exportData () {
      const params = this.getParams()
      this.$emit('loading', true)
      const response = await paginatedRequest(ReportingAPI.property.emails, this.$route.params.pid, params)
      const messages = []
      for (const conversation of response.data) {
        for (const message of conversation.messages) {
          const adCopy = shallowCopy(conversation.ad)
          delete adCopy.status
          messages.push({
            ...message,
            conversation_id: conversation.id,
            ad: adCopy
          })
        }
      }
      this.$emit('loading', false)
      return messages
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table-responsive {
  overflow-x: hidden;
}
::v-deep .table {
  table-layout: fixed;
}
::v-deep td {
  word-wrap: break-word;
}
::v-deep .conversation {
  margin: 0;
  border-spacing: 0;
  thead {
    visibility: hidden;
    line-height: 0;
    th {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  tr {
    background-color: #e9ebf180;
    &:not(:first-child) {
      td {
        border-top: none;
      }
    }
    &:not(:last-child) {
      td {
        border-bottom: none;
      }
    }
  }
}
</style>
