var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.tableType,_vm._b({tag:"component",on:{"fetch":function($event){return _vm.$emit('fetch')},"page-change":function($event){return _vm.$emit('page-change', $event)}},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"heavy"},[_vm._v(_vm._s(_vm._f("formatDateTime")(item.timestamp,'MMM dd, yyyy')))]),_c('span',{staticClass:"faded"},[_vm._v(" at "+_vm._s(_vm._f("formatDateTime")(item.timestamp,'hh:mm a')))])])]}},{key:"forwarded",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CIcon',{class:item.forwarded ? 'success' : 'failure',attrs:{"name":item.forwarded ? 'md-check-circle' : 'md-error'}})],1)]}},{key:"sender",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.isProspect(item.sender) ? "Prospect" : item.sender)+" ")])]}},{key:"recipient",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.isProspect(item.recipient) ? "Prospect" : item.recipient)+" ")])]}},{key:"message_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.message_count)?_c('span',[_c('span',{staticClass:"message-count"},[_vm._v(" "+_vm._s(item.message_count)+" ")]),_c('span',{staticClass:"expand-button",class:{ invisible: item.message_count <= 1 },on:{"click":function () { return _vm.$emit('update:expanded', item.id); }}},[_c('CIcon',{class:{ expanded: _vm.expanded[item.id] },attrs:{"name":"md-expand-more"}})],1)]):_vm._e()])]}},(Boolean(_vm.$scopedSlots.details))?{key:"details",fn:function(ref){
var item = ref.item;
return [_vm._t("details",null,{"item":item})]}}:null],null,true)},'component',_vm.tableProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }