<template>
  <div>
    <component
      :is="tableType"
      v-bind="tableProps"
      @fetch="$emit('fetch')"
      @page-change="$emit('page-change', $event)"
    >
      <template #date="{ item }">
        <td>
          <span class="heavy">{{ item.timestamp | formatDateTime('MMM dd, yyyy') }}</span>
          <span class="faded"> at {{ item.timestamp | formatDateTime('hh:mm a') }}</span>
        </td>
      </template>
      <template #forwarded="{ item }">
        <td class="text-center">
          <CIcon
            :name="item.forwarded ? 'md-check-circle' : 'md-error'"
            :class="item.forwarded ? 'success' : 'failure'"
          />
        </td>
      </template>
      <template #sender="{ item }">
        <td>
          {{ isProspect(item.sender) ? "Prospect" : item.sender }}
        </td>
      </template>
      <template #recipient="{ item }">
        <td>
          {{ isProspect(item.recipient) ? "Prospect" : item.recipient }}
        </td>
      </template>
      <template #message_count="{ item }">
        <td class="text-center">
          <span v-if="item.message_count">
            <span class="message-count">
              {{ item.message_count }}
            </span>
            <span
              class="expand-button"
              :class="{ invisible: item.message_count <= 1 }"
              @click="() => $emit('update:expanded', item.id)"
            >
              <CIcon :class="{ expanded: expanded[item.id] }" name="md-expand-more" />
            </span>
          </span>
        </td>
      </template>
      <template v-if="Boolean($scopedSlots.details)" #details="{ item }">
        <slot name="details" :item="item" />
      </template>
    </component>
  </div>
</template>

<script>
/**
 * EmailTable
 *
 * Table for displaying information for a list of email messages.
 * Uses RAsyncPagedTable or RDataTable depending on the data prop.
 */
import merge from 'lodash.merge'

import { RAsyncPagedTable, RDataTable } from '@/components/tables'

const columns = [
  {
    key: 'date',
    label: 'Date',
    _style: 'width: 200px'
  },
  {
    key: 'forwarded',
    label: 'Sent',
    _style: 'width: 50px'
  },
  {
    key: 'sender',
    label: 'From',
    _style: 'min-width: 350px'
  },
  {
    key: 'recipient',
    label: 'To',
    _style: 'min-width: 350px'
  },
  {
    key: 'subject',
    label: 'Subject Line'
  },
  {
    key: 'message_count',
    label: 'Messages',
    _style: 'width: 100px'
  }
]
const CL_RELAY_DOMAIN = 'reply.craigslist.org'
const ROOOF_MESSAGING_DOMAIN = 'messaging.rooof.com'

export default {
  name: 'EmailTable',
  components: {
    RAsyncPagedTable,
    RDataTable
  },
  props: {
    data: {
      type: [Object, Array],
      required: true
    },
    expanded: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 50
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      columns
    }
  },
  computed: {
    tableType () {
      return Array.isArray(this.data) ? 'RDataTable' : 'RAsyncPagedTable'
    },
    tableProps () {
      const defaults = {
        columnFilter: false,
        tableFilter: false,
        sorter: false
      }
      return this.tableType === 'RAsyncPagedTable'
        ? {
          tableProps: merge(defaults, this.options),
          data: this.data.results,
          columns: this.columns,
          totalRecords: this.data.count,
          page: this.page,
          pageSize: this.pageSize
        }
        : {
          tableProps: merge(defaults, this.options),
          items: this.data,
          fields: this.columns
        }
    }
  },
  methods: {
    isProspect (address) {
      return address.includes(CL_RELAY_DOMAIN) || address.includes(ROOOF_MESSAGING_DOMAIN)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-icon {
  &.expanded {
    transform: rotate(180deg);
  }
  &.success {
    color: $success
  }
  &.failure {
    color: $danger
  }
}
.expand-button {
  float: right;
}
</style>
